import React from "react"
import firebase from "gatsby-plugin-firebase"

function Component() {
  const [data, setData] = React.useState(null)

  React.useEffect(() => {
    firebase
      .storage()
      .refFromURL("gs://sre-website-a43e8.appspot.com/learn_yourself.png")
      .getDownloadURL()
      .then(url => setData(url))
      .catch(err => {
        switch (err.code) {
          case 'storage/object-not-found':
            // File doesn't exist
            break;
          case 'storage/unauthorized':
            // User doesn't have permission to access the object
            break;
          case 'storage/canceled':
            // User canceled the upload
            break;
          case 'storage/unknown':
            // Unknown error occurred, inspect the server response
            break;
          default:
            console.log(err);
        }      
      });
  }, [])

  return <img loading="lazy" src={data} alt=""/>
}

export default Component